import React from 'react';
import { BlueButton } from './';

import styled from 'styled-components';

const CardWrap = styled.div`
  padding: 16px;
  height: 100%;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  width: 100%;
  min-height:316px;
  text-align: center;
  font-size: 18px;
  background-color: ${ props => props.theme.colors.bgLighter };
  border: 2px solid ${ props => props.theme.colors.primary };
  padding: 22px 0;
  border-radius: 8px;

    
  .logo {
    display:block;
    margin: 0 auto;
  }

  p {
    margin: 1rem;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: normal;
  }

  h2{
    font-size: 22px;
    line-height: 27px;
    letter-spacing: normal;
    font-weight: bold;
    text-align: center;
    color: ${ props => props.theme.colors.primary };
  }

  h3 {
    font-size: 30px;
    font-weight: 500;
    line-height: 37px;
    text-align: center;
  }
  
  &:hover {
    button {
      background-color: ${({ theme }) => theme.colors.secondary};
    }

    border: 2px solid ${ props => props.theme.colors.secondary };

    h2, h3 {
      color: ${ props => props.theme.colors.secondary };
    }
  }
`;
const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: auto;
`;

const Icon: React.FC<{ icon: any; height?: number }> = ({ icon, height }) => {
  if (typeof icon === `string`) {
    return <img height={ 72 } width={ 72 } src={icon} />;
  }


  const Element = icon;
  return <Element height={72} width={ 72 } />;
};

type props = {
  icon ?: string,
  title ?: string,
  subtitle ?: string,
  description ?: any,
  button ?: any,
};

const Card: React.FC< props > = props => {
  const { icon, subtitle, title, description, button } = props;
   
  return (
    <CardWrap>
      <StyledCard>
        {( icon && <Icon icon={ icon } /> ) }
        
        { ( title && <h2>{ title }</h2> ) }
        { ( description && <p>{description}</p> ) }
        { ( subtitle && <h3>{subtitle}</h3> ) }
        
        <LinkWrap>
          <BlueButton {...button}>{ button.text }</BlueButton>
        </LinkWrap>
      </StyledCard>
    </CardWrap>
  );
};

export default Card;
