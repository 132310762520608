import React from 'react';
import Grid from "styled-components-grid";
import Hero from "../components/Hero";
import { GridCentered } from "../components/shared";
import { useTranslation, Trans } from 'react-i18next';
import Cards from '../components/shared/Cards';
import VigorMark from '../assets/svgs/vig-mark.svg';
import styled from "styled-components";

const GuideLinkWrap = styled.div`
  a {
    color: ${props => props.theme.colors.primaryLighter};
  }
`;

const Features: React.FC<{}> = (props) => {  
  const { t } = useTranslation();

  const buttons = {
    mainnetButton: {
      as: "a",
      margin: "0",
      fullWidth: false,
      text: t( `vigorStablecoin` ),
      href: 'https://app.vigor.ai'
    },
    testnetButton: {
      as:"a",
      margin: "0",
      fullWidth: false,
      text: t( `productsPage.testnetButton` ),
      href: 'https://try.vigor.ai'
    }
  };

  return (
    <React.Fragment>
      <GridCentered>
        <Grid.Unit size={{sm: 10/12 }}>
        <Hero
            title={ t(`productsPage.titlePage`) }
            content={t(`productsPage.descriptionPage`)}
            small
          />

        </Grid.Unit>
      </GridCentered>

      <GridCentered>
        <Grid.Unit size={{ sm: 10/12, md: 5/12 }}>
          <Cards title={ t(`productsPage.mainnetTitle`) }
                 description={ t(`productsPage.mainnetDescription`) }
                 icon={ VigorMark }
                 button={ buttons.mainnetButton } />
        </Grid.Unit>
        <Grid.Unit size={{ sm: 10/12, md: 5/12 }}>
          <Cards title={ t(`productsPage.testnetTitle`) }
                 description={ t(`productsPage.testnetDescription`) }
                 icon={ VigorMark }
                 button={ buttons.testnetButton } />
        </Grid.Unit>

        <GuideLinkWrap>
          {t('productsPage.GuideNote')}: <a href={'https://docs.vigor.ai'} target={'_blank'} rel={'noopener noreferrer'}>docs.vigor.ai</a>
        </GuideLinkWrap>
      </GridCentered>
    </React.Fragment>
  );
};

export default Features;
